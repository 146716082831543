.buscador-generico-container {
  position: relative;
  top: 15%;
}

.buscador-generico-opciones {
  width: 60%;
  margin: 0 auto;
}

.buscador-generico-header {
  text-align: center;
  padding-bottom: 0rem;
}

.buscador-generico-body {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}
.buscador-generico-identificacion {
  /* position: fixed; */
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.buscador-generico-gestion-opciones {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  justify-content: space-around;
}
