.detalleSeccionConversacionSide-container {
  /* padding: 0.5rem; */
  /* height: 100vh; */
  /*   display: grid;
  grid-template-rows: 5% auto 5%; */
}

.detalleSeccionConversacionSide-mensajeria {
  margin: 6.5rem 0 0 0;
  /*  position: relative; */
}

.formularioSide-input-mensajes {
  min-width: 100%;
  padding: 0.5rem 0;
}
