.formulario-cabecera {
  /*  font-size: 1.3rem; */
  line-height: 0;
  color: aliceblue;
  /* color: white; */
  font-weight: 400;
  letter-spacing: -0.1px;
}
.accordion-summary {
  background: -moz-linear-gradient(
    47deg,
    rgba(117, 8, 10, 1) 0%,
    rgba(199, 52, 54, 1) 88%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    47deg,
    rgba(117, 8, 10, 1) 0%,
    rgba(199, 52, 54, 1) 88%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    47deg,
    rgba(117, 8, 10, 1) 0%,
    rgba(199, 52, 54, 1) 88%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#C73436', endColorstr='#75080A', GradientType=0 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    47deg,
    rgba(117, 8, 10, 1) 0%,
    rgba(199, 52, 54, 1) 88%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    47deg,
    rgba(117, 8, 10, 1) 0%,
    rgba(199, 52, 54, 1) 88%
  );
}
