.header-client-container {
  margin: 1rem 0 1.3rem 0;
}

.header-client-information {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.header-client-card {
  min-height: "10vh";
  max-height: "auto";
  border-radius: "25px";
}

@media (max-width: 600px) {
  .header-client-information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "name name"
      "user user1"
      "contact contact1";
  }
  .header-client-sectionName {
    display: none;
  }
  .header-client-sectionNombre {
    grid-area: name;
    display: flex;
    width: 100%;
    align-items: baseline;
  }

  .header-client-container h4 {
    margin: 0.6rem 0;
  }

  .header-client-sectionNombre h4 {
    padding-right: 5px;
  }
  .header-client-sectionIdentificacion {
    grid-area: user;
  }

  .header-client-sectionFecha {
    grid-area: user1;
  }
  .header-client-sectionTelefono {
    grid-area: contact;
  }
  .header-client-sectionCelular {
    grid-area: contact1;
  }
}
