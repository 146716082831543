.listaNegra-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-colaborador-container {
  padding: 2rem;
}

.buscador-listaNegra-opciones {
  display: flex;
  gap: 0.7rem;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.semaphore {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5a5a5a;
  border-radius: 10px;
  padding: 5px;
  margin: 15px 0;
}

.light {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}

.red {
  background-color: #ff3232;
}

.yellow {
  background-color: #ffd633;
}

.green {
  background-color: #00c726;
}

.light.on {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.8);
}

.light.off {
  opacity: 0.3;
}
