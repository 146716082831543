.detalleCabecera-container {
  display: grid;
  grid-template-columns: auto 7%;
}

.botonSalir-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
