.informacionPrecancelacion-container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
}
.informacionPrecancelacion_documentos {
  width: 80%;
}
