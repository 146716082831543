.cliente-informacion-perfil {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 2rem;
}

.cliente-informacion-seccion {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1.3rem 0rem 0rem 0rem;
}
