.buscador-movimientos-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buscador-movimientos-header {
  padding: 1rem;
}

.buscador-movimientos-titulo {
  width: 100%;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buscador-movimientos-opciones {
  display: grid;
  grid-template-columns: 55% auto;
}

.buscador-movimientos-opciones__inputs {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.buscador-movimientos-opciones__buttons button {
  margin: 0 0.4rem;
}

@media (max-width: 600px) {
  .buscador-movimientos-opciones {
    display: flex;
    flex-direction: column; /* Cambia la dirección de los elementos a columna */
    align-items: center; /* Centra los elementos verticalmente */
    gap: 2.5rem;
  }

  .buscador-movimientos-opciones__inputs {
    /*  width: 100%; */
    display: flex;
    flex-direction: column;
    /*   justify-content: center;*/
    gap: 0.5rem;
  }

  .buscador-movimientos-opciones__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 0.5rem;
  }
}
