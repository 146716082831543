.client-search-item {
  /* width: 30vw;
    height: auto; */
  background-color: #ffffff;
  /* centrar vertical y horizontalmente */
  position: absolute;
  top: 10rem;
  left: 35rem;
  width: 40vw;
  padding: 2rem;
}

.buscador-container {
  position: absolute;
  top: 13rem;
  left: 30rem;
  width: 40vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 35vh;
}
.buscador-cabecera {
  margin: 0 auto;
  padding-bottom: 2rem;
}
.buscador-body {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}
.buscador-identificacion {
  margin: 0 auto;
}
.buscador-botones {
  display: flex;
  justify-content: space-around;
}
