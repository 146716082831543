.clienteCitas-box {
  margin: 0 auto;
  margin-top: 2rem;
  width: 85%;
  height: auto;
  padding: 2rem;
}
.clienteCitas-content-information {
  padding: 2rem;
}
