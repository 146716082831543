.actualizarPagos-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}
.actualizarPagos-logos-container {
  overflow: hidden;
  text-align: center;
}

.actualizarPagos-logos {
  /*  width: 300px;
  height: 600px; */
  /*  margin: 0 1rem; */
  padding: 0.5rem;
  border-radius: 50%;
  width: 90%;
  filter: opacity(0.5);
  border: 10px solid rgba(102, 102, 102, 0.301);
}

.actualizarPagos-logos:hover {
  filter: opacity(1);
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
