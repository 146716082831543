.movimientoCliente-container,
.movimientoCliente-container-colapse {
  display: grid;
  gap: 1rem;
  transition: all 0.2s;
}

.movimientoCliente-container {
  grid-template-columns: 15vw auto;
  /*  grid-template-rows: 10vh auto; */
}

.movimientoCliente-container-colapse {
  grid-template-columns: 7vw auto;
  /* grid-template-rows: 10vh auto; */
}

.movimientoCliente-menu {
  display: flex;
  flex-direction: column;
  gap: 1.7rem;

  /* display: grid;
  grid-template-rows: 10vh auto;
  gap: 3rem; */
}

.movimientoCliente-menu-header {
  min-height: 10.5vh;
  max-height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.movimientoCliente-detalle {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.movimientoCliente-detalle-informacionContainer {
  max-width: 100%;
  min-width: 100%;
}

.movimientoCliente-informacion {
  /*  min-width: 100em; */
  /*  max-width: 100vw; */
  height: 100%;
}
/* secction tablas */
.movimientoCliente-opciones {
  display: grid;
  grid-template-columns: auto 6%;
  align-items: center;
}

.detalleOpciones-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.movimientoCliente-menu-container {
  position: relative;
}

.movimientoCliente-menu-buttonColapse {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1400px) {
  .movimientoCliente-detalle {
    gap: 1rem;
  }
}
