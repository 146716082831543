.debitoCuenta-form-seccion {
  width: 100%;
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.debitoCuenta-form-controls {
  width: 100%;
  /* width: fit-content; */
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.debitoCuenta-form-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 15%;
}

.debitoCuenta-form-switch {
  /* width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem; */

  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr auto 1fr;

  padding-bottom: 0.3rem;
}

@media (max-width: 600px) {
  .debitoCuenta-form-seccion {
    display: flex;
    flex-direction: column; /* Cambia la dirección de los elementos a columna */
    gap: 1.5rem;
  }
  .debitoCuenta-form-options {
    padding: 0 15% 0 0;
  }
}
