@import url(./__agendamientoCitasMedicas.css);
@import url(./__informacionClienteCitas.css);

.header-queja-grid {
  display: grid;
  grid-template-columns: 8% auto;
  padding: 0.8rem 0;
}

.header-queja-detalle {
  /* padding: 0.8rem 0; */
}

.footer-queja-detalle {
  margin: 0 1rem 0 2.5rem;
}
