/* styles.css */
.detalleValoresDeuda-transition {
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.detalleValoresDeuda-container {
  display: grid;
  grid-template-columns: 1.7fr 1.7fr 1.2fr;
}

.detalleValoresDeuda-container-simple {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.detalleValoresDeuda-form {
  width: 90%;
}

.detalleValoresDeuda-form-simple {
  width: 85%;
  margin: 0 auto;
}
