@import url(__cardCancelacionSeguro.css);
@import url(__informacionPrecancelacion.css);
@import url(__cambioEstado.css);
@import url(__listaNegra.css);

#frame_docs {
  width: 100vw;
  height: 100vh;
  border: 0px;

  zoom: 0.9;
  -moz-transform: scale(0.95);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.95);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.95);
  -webkit-transform-origin: 0 0;
}

.devolucion-informacion-seccion {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0rem 1rem 1rem 0rem;
}

.notificacion_cancelacion_seguro {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.informacion_notificacion_seguro {
  padding: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.detalle_notificacion_seguro {
  margin-left: 2rem;
}

.boton_notificacion_seguro {
  width: 45%;
}

.detalle-desestimado {
  display: flex;
  gap: 4px;
}

.detalle-desestimado h3 {
  margin: 0.5rem 0;
}
