@import url(./__buscarPorIdentificacion.css);
@import url(./__debitoCuenta.css);

.container-cliente {
  width: 100%;
  height: auto;
}
.form-container-info {
  /* border: 1px solid black; */
  width: 93%;
  margin: 0 auto;
  /* padding-top: 0.5rem; */
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.form-separator-optional {
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: rgb(243, 243, 243);
  padding: 1rem;
}
.accordion-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.select-solicitud-local {
  width: 50rem;
}


.boton-firma-digital {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 2rem;
  width: 50%;
  text-align: center;
}
.link-boton-firma {
  box-shadow: 0px 10px 14px -7px #276873;
  background: linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
  background-color: #599bb3;
  border-radius: 8px;
  display: inline-block;
  cursor: pointer;
  color: #111827;
  font-family: Arial;
  font-size: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.link-boton-firma a {
  text-decoration: none;
  outline: none;
}
.link-boton-firma img {
  width: 15%;
}

.link-boton-firma:hover {
  background: linear-gradient(to bottom, #408c99 5%, #599bb3 100%);
  background-color: #408c99;
}
.tipoSiniestro-tipos-sinseguro:active {
  position: relative;
  top: 1px;
}

.politicsDetail--container {
  display: flex;
}

.politicsDetail--link {
  cursor: pointer;
}
/* .accordion-item {

  background: -moz-linear-gradient(
    12deg,
    rgba(255, 105, 108, 1) 0%,
    rgba(255, 255, 255, 1) 46%
  );

  
  background: -webkit-linear-gradient(
    12deg,
    rgba(255, 105, 108, 1) 0%,
    rgba(255, 255, 255, 1) 46%
  );


  background: -o-linear-gradient(
    12deg,
    rgba(255, 105, 108, 1) 0%,
    rgba(255, 255, 255, 1) 46%
  );


  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF696C', endColorstr='#FFFFFF', GradientType=1 );


  background: -ms-linear-gradient(
    12deg,
    rgba(255, 105, 108, 1) 0%,
    rgba(255, 255, 255, 1) 46%
  );

 
  background: linear-gradient(
    12deg,
    rgba(255, 105, 108, 1) 0%,
    rgba(255, 255, 255, 1) 46%
  );
} */

.accordion-item {
  background-color: black;
  color: aqua;
}
