.header-reporte-container {
  margin: 0rem 5rem;

  padding: 0 0 2rem 0;
}

.header-reporte-detalle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-reporte-information {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.header-reporte-fechas {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.reportes-container {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3.5rem 1.5rem;
}
