.tipoSiniestro-container {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.listaSiniestros_titulo {
  text-align: center;
  padding-top: 1rem;
}

.tipoSiniestro-siniestros {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
}

.tipoSiniestro-tipos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 30%;
  text-align: center;
}

.tipoSiniestro-imagenes {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40rem;
  padding: 2rem;
  height: auto;
  justify-content: space-around;
}

.tipoSiniestro-imagenes img {
  width: 5rem;
}

.header-grid-solicitud {
  /*   background-color: white;
  color: black; */
}
