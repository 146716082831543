.header-client-container {
  margin: 1rem 0 2rem 0;
}

.header-client-information {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

