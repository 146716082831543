.header-cobranza-container {
  margin: 1rem 0 2rem 0;
}

.header-cobranza-information {
  padding: 0.6rem 2rem;
  display: grid;
  grid-template-columns: 0 auto;
}

.header-cobranza-titulo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-cobranza-detalle {
  width: auto;
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.header-cobranza-detalle-filas {
  padding: 0 0 0 0;
  /*  padding: 0 1.5rem 0 0; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-cobranza-detalle-filas > h5 {
  margin: 0.6rem;
}
