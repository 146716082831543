@import url(./__clienteInformacion.css);
@import url(./__buscadorPorIdentificacion.css);
@import url(./__headerinformacionCliente.css);
@import url(./__actualizarContacto.css);

.container-cliente {
  width: 100%;
  height: auto;
}
.form-container-info {
  /* border: 1px solid black; */
  width: 93%;
  margin: 0 auto;
  /* padding-top: 0.5rem; */
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.form-separator-optional {
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: rgb(243, 243, 243);
  padding: 1rem;
}

.accordion-item {
  background-color: black;
  color: aqua;
}
