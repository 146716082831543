.header-traslado-container {
  margin: 1rem 0 2rem 0;
/*   height: 4vh; */
}

.header-traslado-grid {
  display: grid;
  grid-template-columns: 15% auto;
  padding: 0.8rem 0;
}

.header-traslado-titulo {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.header-traslado-body {
  display: flex;
  flex-direction: column;
}

.header-traslado-information {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* height: 15rem;
  margin-bottom: 2rem;
  padding: 1.5rem; */
}

.header-traslado-informacion {
  display: flex;
  padding: 0;
  gap: 0.5rem;
}
.header-traslado-informacion h4 {
  margin: 0.6rem 0;
}

.traslado-form-formulario {
 /*  margin-top: 16rem; */
}

.header-traslado-titulo h2 {
  margin: 2px;
}

.header-traslado-detalle {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  /* margin-bottom: 5%; */
  margin: 0.6rem 0 0.6rem 5.5%;
}

.header-traslado-detalle h4 {
  margin-top: 0;
}
