.client-search-item {
  /* width: 30vw;
    height: auto; */
  background-color: #ffffff;
  /* centrar vertical y horizontalmente */
  position: absolute;
  top: 10rem;
  left: 35rem;
  width: 40vw;
  padding: 2rem;
}

.buscador-container-preev {
  top: 13rem;
  left: 30rem;
  padding: 2rem;
  /* display: flex; */
  flex-direction: column;
  /* height: 35vh; */
}
.buscador-cabecera-preev {
  margin: 0 auto;
  padding-bottom: 2rem;
}
.buscador-body-preev {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
}
.buscador-identificacion-preev {
  margin: 0 auto;
}
.buscador-botones-preev {
  display: flex;
  justify-content: space-between;
}
.buscador-botones-centro {
  /* display: flex; */
  justify-content: center;
}
