@import url(__cabeceraSolicitud.css);
@import url(__gestionLlamadas.css);

.gestionCobranza-permisos-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gestionCobranza-permisos-listaPermisos {
  width: 50%;
  padding: 0 1rem;
}

.gestionCobranza-permisos-detalle {
  display: flex;
  gap: 2rem;
}

.parametrosCobranza-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 3rem 0;
}

.gestionCobranza-permisos-tabla {
  border-collapse: collapse;
  width: 100%;
}
.gestionCobranza-permisos-tabla th,
td {
  border: 1px solid black;
  padding: 8px;
}

.gestionCobranza-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
