.tablaCuotas-container {
  display: grid;
  height: 85%;
  gap: 1rem;
  grid-template:
    [r1a] "a   a" auto [r1b] /*primera fila*/
    [r2a] "b   c" auto [r2b] /*segunda fila*/
    / [c1] 50% [c2] auto; /*la estructura de columnas*/
}

.tablaCuotas-container > div:first-child {
  grid-area: a;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0px 0px 13px 0px rgba(229, 25, 31, 1);
}

.tablaCuotas-container > div:nth-child(2) {
  grid-area: b;
  width: 75%;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0px 0px 13px 0px rgba(229, 25, 31, 1);
  margin-bottom: 1rem;
}

.tablaCuotas-container > div:nth-child(3) {
  grid-area: c;
  width: 96%;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0px 0px 13px 0px rgba(229, 25, 31, 1);
  margin-bottom: 1rem;
}
