.header-datosSolicitud-information {
  padding: 0.6rem 2rem;
  display: grid;
  grid-template-columns: 0 auto;
}

.header-datosSolicitud-titulo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-datosSolicitud-detalle {
  width: auto;
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.header-datosSolicitud-detalle-filas {
  padding: 0 0 0 0;
  /*  padding: 0 1.5rem 0 0; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-datosSolicitud-detalle-filas > h5 {
  margin: 0.6rem;
}

@media screen and (max-width: 769px) {
  .header-datosSolicitud-detalle {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}
