.gestion-siniestros-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transform: translatey(-10%);
}

.buscador-gestion-opciones {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.buscador-siniestros-titulo {
  width: 100%;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buscador-siniestros-container {
  margin: 0rem 5rem;
  padding: 0 0 2rem 0;
}

.header-colaborador-header {
  padding: 2rem;
}
