@import url(__estadosConfirmacion.css);
@import url(__detalleCoutas.css);

.container-cliente {
  width: 100%;
  height: auto;
}
.header-client-information {
  display: flex;
  flex-direction: row;
}

.client-search-item {
  /* width: 30vw;
  height: auto; */
  background-color: #ffffff;
  /* centrar vertical y horizontalmente */
  position: absolute;
  top: 10rem;
  left: 35rem;
  width: 40vw;
  padding: 2rem;
}

.form-container-info {
  /* border: 1px solid black; */
  width: 93%;
  margin: 0 auto;
  /* padding-top: 0.5rem; */
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.form-separator-optional {
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: rgb(243, 243, 243);
  padding: 1rem;
}
.accordion-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.accordion-item {
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    12deg,
    rgba(255, 105, 108, 1) 0%,
    rgba(255, 255, 255, 1) 46%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    12deg,
    rgba(255, 105, 108, 1) 0%,
    rgba(255, 255, 255, 1) 46%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    12deg,
    rgba(255, 105, 108, 1) 0%,
    rgba(255, 255, 255, 1) 46%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF696C', endColorstr='#FFFFFF', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    12deg,
    rgba(255, 105, 108, 1) 0%,
    rgba(255, 255, 255, 1) 46%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    12deg,
    rgba(255, 105, 108, 1) 0%,
    rgba(255, 255, 255, 1) 46%
  );
}

.medioPago_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.medioPago_box {
  width: 90%;
  padding: 1rem 0;
}
.medioPago_paper {
  padding: 2rem 2rem;
}

.medioPago_URL {
  display: flex;
  align-items: center;
  justify-content: center;
}

.medioPago_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}
