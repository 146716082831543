.simuladorCuota_container {
  width: 80%;
  margin: 0 auto;
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  gap: 1.5rem;
}

@media (max-width: 600px) {
  .simuladorCuota_container {
    width: 100%;
  }
}
