.anularAutorizaciones_container {
  display: flex;
  flex-direction: column;
}

.anularAutorizaciones_actions {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
}
