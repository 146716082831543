/* .initialPage-container {
  margin: 0 auto;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */

.initialPage-container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-image: url("../../../../../public/images/logo/fondoSCS.png");
  background-repeat: no-repeat;
  margin: 0;
}

/* .initialPage-main {
  text-align: center;
}

.initialPage-main img {
  width: 50%;
}
 */
