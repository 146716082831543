.agendamiento-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.agendamiento-header {
  text-align: center;
  margin: 2rem;
}
.agendamiento-content-options {
  display: flex;
  justify-content: space-evenly;
}
