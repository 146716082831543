.estilosGenericos--boton {
  color: #e1251b;
  background-color: #ffffff;
  border: 2px solid #e1251b;
  border-radius: 25px;
  font-weight: 900;
  padding: 9px 0px;
}

.estilosGenericos--boton:hover {
  color: #ffffff;
  background-color: #e1251b;
  font-weight: 900;
}

.estilosGenericos--boton:active {
  color: #ffffff;
  background-color: #f4f4f4;
  border: 2px solid #f4f4f4;

  border-radius: 25px;
  font-weight: 900;
  padding: 9px 0px;
}

.estilosGenericos--boton_loading {
  color: #f5f7fa;
  color: rgba(239, 239, 239, 0.3);
  background-color: #f4f4f4;
  border: 2px solid #f4f4f4;

  border-radius: 25px;
  font-weight: 900;
  padding: 9px 0px;
}
