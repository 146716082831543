.cambioEstado-container {
  display: grid;
  grid-template-columns: 70% auto;
}

.cambioEstado-informacion-tabla {
  grid-area: 1;
}

.cambioEstado-seccion-botones {
  /* width: 100%; */
  margin: 1.5rem 0 0 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 0 0 0 20%;
}

.cambioEstado-icono-validacion {
  margin: -0.75rem 3rem;
}
