@import url(__buscadorMovimientos.css);
@import url(__detalleTransacciones.css);
@import url(__detalleCabeceraTransaccional.css);
@import url(__detalleTransaccionesMobile.css);
@import url(__cabeceraInformacionSolicitud.css);
@import url(__cabeceraCuentaCredito.css);
@import url(__simuladorCuota.css);
@import url(__detalleRefinanciamiento.css);
@import url(__anularAutorizaciones.css);

.buscador-transaccional-container {
  position: absolute;
  top: 13rem;
  left: 25rem;
  width: 40vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 40vh;
}

.buscador-transaccional-cabecera {
  /* width: 100vw; */
  margin: 0 auto;
  /* padding-bottom: 2rem; */
}

.buscador-transaccional-gestion-opciones {
  display: flex;
  flex-direction: row;
  /* height: 100%;
  width: 100%; */
  justify-content: space-around;
}

.buscador-transaccional-gestion-opciones-id {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: center;
}

.valorPagar-header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.valorPagar-header-informacion {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bloqueoClientes-detalle {
  margin: 3%;
  text-align: center;
}

.bloqueoClientes-crear-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.bloqueoClientes-crear-switch {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.movimientoCliente-menu-items::-webkit-scrollbar {
  width: 5px;
}

.movimientoCliente-menu-items::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  height: 10px;
}

.movimientoCliente-consumos-estado {
  background: linear-gradient(180deg, #c9f2c1 0%, #a8e6a1 100%);
  border-radius: 15px;
  padding: 4px;
  box-shadow: 0 2px 3px #a8e6a1; /* Sombra suave */
}

.movimientoCliente-consumos-estado-activa {
  background: linear-gradient(180deg, #c9f2c1 0%, #a8e6a1 100%);
  border-radius: 15px;
  padding: 4px;
  box-shadow: 0 2px 3px #a8e6a1; /* Sombra suave */
}

.movimientoCliente-consumos-estado-noactiva {
  background: linear-gradient(180deg, #ffd966 0%, #ffb84d 100%);
  border-radius: 15px;
  padding: 4px;
  box-shadow: 0 2px 3px #ffb84d; /* Sombra suave */
}
