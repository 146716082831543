.confirmar-estados-servicios {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem 2rem 0 0;
}

.seguros-listaPagos-container {
  margin-top: 1rem;
}
