.header-cuenta-information {
  padding: 1rem 0.5rem 0.5rem 0.5rem;
}

/* Contenedor de la cuadrícula */
.header-cuenta-detalle-header {
  width: 90%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 0.7fr 1fr 3fr 0.7fr 3fr;
  /*  gap: 10px; */
  background-color: #fff;
}

.header-cuenta-detalle-header > div > h4 {
  margin: 0;
  text-align: left;
  font-size: 1em;
}
.header-cuenta-detalle-header > div {
  padding: 0.15rem 10px;
}

/* Contenedor de la cuadrícula */
.header-cuenta-valores {
  display: grid;
  /* grid-template-columns: repeat(5, 1fr 0.8fr); */
  grid-template-columns: 1fr 0.8fr 1fr 0.8fr 1.05fr 0.55fr 1.1fr 0.6fr 0.6fr 0.5fr;
  /*  grid-template-rows: repeat(3, 1fr); */
  background-color: #fff;
  /* padding: 0 5px; */
}

.header-cuenta-valores > div {
  margin-bottom: 0.2rem;
}

.header-cuenta-valores > div > h4 {
  margin: 0;
  text-align: left;
  font-size: 1em;
}
.header-cuenta-lastRow {
  display: grid;
  grid-template-columns: 1fr 0.8fr 1fr 0.8fr 1.05fr 0.55fr 1.1fr 0.6fr 0.6fr 0.5fr;
  /* padding: 0 5px; */
  /* grid-template-columns: repeat(5, 2fr 1.5fr); */
  /*  grid-template-rows: repeat(3, 0.8fr); */
  /* padding: 0 4px; */
}
.header-cuenta-lastRow > div > h4 {
  margin: 0;
  text-align: left;
  font-size: 1em;
}

@media screen and (max-width: 1400px) {
  .header-cuenta-detalle-header {
    width: 98%;
    grid-template-columns: 0.7fr 0.6fr 0.45fr 1.8fr 0.4fr 1.3fr;
  }

  .header-cuenta-detalle-header > div > h4 {
    font-size: 0.8em;
  }
  .header-cuenta-detalle-header > div {
    padding: 0;
    padding-bottom: 0.3rem;
  }

  .header-cuenta-valores {
    grid-template-columns: 1.2fr 0.8fr 1.3fr 0.8fr 1.2fr 0.75fr 1.1fr 0.6fr 0.65fr 0.6fr;
    /* grid-template-columns: repeat(5, 2fr 1.5fr); */
    /*  grid-template-rows: repeat(3, 0.8fr); */
    /* padding: 0 4px; */
  }
  .header-cuenta-valores > div {
    margin-bottom: 0.15rem;
  }
  .header-cuenta-valores > div > h4 {
    margin: 0;
    font-size: 0.75em;
  }

  .header-cuenta-lastRow {
    grid-template-columns: 1.4fr 0.6fr 1.3fr 0.8fr 1.4fr 0.55fr 1.4fr 0.45fr 0.65fr 0.6fr;
    /* grid-template-columns: repeat(5, 2fr 1.5fr); */
    /*  grid-template-rows: repeat(3, 0.8fr); */
    /* padding: 0 4px; */
  }
  .header-cuenta-lastRow > div > h4 {
    margin: 0;
    font-size: 0.75em;
  }
}
