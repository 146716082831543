@import url(__detalleSeccionConversacionSide.css);
@import url(__cabeceraChatSide.css);

.unread-message-count {
  width: 1rem;
  height: 1rem;
  background-color: #ff5722;
  color: white;
  border-radius: 50%;
  /* padding: 5px; */
  font-size: 11px;
}

.chatSide-container {
  margin: 0 0.5rem;
  padding: 1rem 0;
  height: auto;
  background-color: #ffffff;
}
