.actualizarContacto-container {
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;

  /* margin-bottom: 2rem;
  margin: 0 auto;
  flex-direction: column;
  gap: 2rem;
   */
}

@media (max-width: 600px) {
  .actualizarContacto-container {
    width: 100%;
  }
}
