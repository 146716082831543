.movimientoClienteMobile-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.movimientoClienteMobile-item {
  margin: 0 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.movimientoClienteMobile-detalle-items {
  margin: 0.5rem;
}
