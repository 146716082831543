@import url(./__formSeguimientoComentarios.css);
@import url(./__tipoSiniestro.css);
@import url(./__formSeguimientoPagos.css);
@import url(./__buscadorGestionSeguros.css);
@import url(./__formResolucion.css);
@import url(./__headerInformacionTrasladoIndirecto.css);
@import url(./__comentariosSiniestro.css);
@import url(./__formSiniestro.css);
@import url(./__cerrarSiniestro.css);

.form-documentos-container {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.form-documentos-title {
  text-align: center;
  padding: 2rem 2rem 1rem 2rem;
}

.form-documentos-archivos {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.form-documentos-archivos-items {
  margin: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.form-documentos-archivos-cabecera {
  padding: 2rem;
  display: grid;
  grid-template-columns: 0.75fr 1fr 1fr;
}
.form-documentos-opciones {
  display: flex;
  gap: 1rem;
}
/* .form-documentos-opciones button {
    margin: 0.5rem;
  } */

.pagosSiniestro-opciones-pago {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
}

.siniestro-informacion-estatica {
  width: 75%;
  display: flex;
  justify-content: space-between;
}

.tipoSiniestro-tipos-sinseguro {
  box-shadow: 0px 10px 14px -7px #276873;
  background: linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
  background-color: #599bb3;
  border-radius: 25px;
  display: inline-block;
  cursor: pointer;
  color: #111827;
  font-family: Arial;
  font-size: 20px;
  font-weight: bold;
  padding: 13px 32px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #3d768a;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: transparent;
}

.tipoSiniestro-tipos-sinseguro a {
  text-decoration: none;
  outline: none;
}
.tipoSiniestro-tipos-sinseguro img {
  width: 20%;
}

.tipoSiniestro-tipos-sinseguro:hover {
  background: linear-gradient(to bottom, #408c99 5%, #599bb3 100%);
  background-color: #408c99;
}

.tipoSiniestro-tipos-sinseguro:active {
  position: relative;
  top: 1px;
}

.modal_cerrar_siniestro {
  margin: 1rem 0 0 0;
  text-align: center;
}
