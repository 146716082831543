.siniestro-form-botones {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 1.5rem 1.5rem 0rem 0rem;
}

.siniestro-codigoSiniestro {
  width: 15%;
  padding: 0 0 0 0.5rem;
}
.siniestro-colaborador {
  margin-left: 0.5rem;
}
